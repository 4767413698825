module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "& Stuff & Junk", // Navigation and Site Title
  siteTitleAlt: "& Stuff & Junk - Don't Waste Time on Junk!", // Alternative Site title for SEO
  siteTitleManifest: "AndStuffAndJunk",
  siteUrl: "https://andstuffandjunk.com", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteHeadline: "Writing and publishing content", // Headline for schema.org JSONLD
  siteBanner: "/social/banner.jpg", // Your image for og:image tag. You can find it in the /static folder
  favicon: "src/favicon.png", // Your image for favicons. You can find it in the /src folder
  siteDescription:
    "We know stuff and we know junk. We know when stuff IS junk. Listen to us and don't waste time on junk!", // Your site description
  author: "RSHI", // Author for schemaORGJSONLD
  siteLogo: "/social/logo.png", // Image for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: "@andstuffandjunk", // Twitter Username - Optional
  ogSiteName: "andstuffandjunk", // Facebook Site Name - Optional
  ogLanguage: "en_US", // Facebook Language
  googleAnalyticsID: "UA-270123-13",

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: "#3498DB",
  backgroundColor: "#2b2e3c"
};
